import React from "react"
import { graphql } from "gatsby"
import {
  AppBar,
  Breadcrumb,
  Footer,
  Layout,
  SEO,
  SnsShare,
  ArticleSchema,
} from "../components"
import { TemplatesArticleQuery } from "../../types/graphql-types"
import { Slug } from "../views/articles"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const { previous, next } = pageContext

  const title: string = post.frontmatter.title
  const published: Date = new Date(post.frontmatter.published)
  const updated: Date | null = post.frontmatter.updated
    ? new Date(post.frontmatter.updated)
    : null
  const slug: string = post.fields.slug.slice(1).slice(0, -1)
  const description: string = post.excerpt
  const tags: Array<string> = post.frontmatter.tags
  const image: string | null =
    post.frontmatter.image?.childImageSharp?.fixed?.src || null
  const toc: string = post.tableOfContents
  const body: string = post.html

  return (
    <Layout>
      <SEO title={title} description={description} slug={slug} image={image} />
      <ArticleSchema
        title={title}
        description={description}
        published={published}
        updated={updated}
        slug={slug}
        image={image}
      />
      <AppBar />
      <SnsShare title={title} slug={slug} />
      <Breadcrumb
        showNav={true}
        itemList={[
          { name: "トップ", slug: "" },
          { name: "記事一覧", slug: "articles" },
          { name: title, slug: slug },
        ]}
      />
      <Slug
        title={title}
        published={published}
        updated={updated}
        body={body}
        tags={tags}
        toc={toc}
      />
      <Footer slug={slug} copyright={{ publishDate: updated || published }} />
    </Layout>
  )
}

export default BlogPostTemplate

export const query = graphql`
  query TemplatesArticle($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(truncate: true)
      html
      frontmatter {
        title
        published(formatString: "YYYY-MM-DD")
        updated(formatString: "YYYY-MM-DD")
        tags
        image {
          childImageSharp {
            fixed {
              src
            }
          }
        }
      }
      fields {
        slug
      }
      tableOfContents(maxDepth: 2)
    }
  }
`
